import { lazy } from 'react'

//prettier-ignore
export const dashboardRoutes = [
  { path: '/', component: lazy(() => import('../pages')) },
  { path: '/roles', component: lazy(() => import('../pages/Roles')) },
  { path: '/events', component: lazy(() => import('../pages/Events')) },
  { path: '/products', component: lazy(() => import('../pages/Products')) },
  { path: '/tickets', component: lazy(() => import('../pages/Tickets')) },
  { path: '/orders', component: lazy(() => import('../pages/Orders')) },
  { path: '/notifications', component: lazy(() => import('../pages/Notifications')) },
  { path: '/admin', component: lazy(() => import('../pages/Admin')) },
  { path: '/admin/directory', component: lazy(() => import('../pages/Users')) },
  { path: '/admin/resources', component: lazy(() => import('../pages/Resources')) },
  { path: '/reports', component: lazy(() => import('../pages/Reports')) },
  { path: '/admin/pop-ups', component: lazy(() => import('../pages/PopUps')) },
  { path: '/sysinfo', component: lazy(() => import('../pages/SysInfo')) },
  { path: '/admin/file-transfer', component: lazy(() => import('../pages/FileTransfer')) },
]

//prettier-ignore
export const manageRoutes = [
  { path: '/roles/create', component: lazy(() => import('../pages/Roles/Create')),},
  { path: '/roles/edit/:id', component: lazy(() => import('../pages/Roles/Edit')),},
  { path: '/events/create', component: lazy(() => import('../pages/Events/Create')),},
  { path: '/events/edit/:id', component: lazy(() => import('../pages/Events/Edit')),},
  { path: '/products/create', component: lazy(() => import('../pages/Products/Create')),},
  { path: '/products/edit/:id', component: lazy(() => import('../pages/Products/Edit')),},
  { path: '/tickets/create', component: lazy(() => import('../pages/Tickets/Create')) },
  { path: '/tickets/edit/:id', component: lazy(() => import('../pages/Tickets/Edit')) },
  { path: '/orders/create', component: lazy(() => import('../pages/Orders/Create')) },
  { path: '/admin/resources/create', component: lazy(() => import('../pages/Resources/Create')) },
  { path: '/admin/resources/edit/:id', component: lazy(() => import('../pages/Resources/Edit')) },
  { path: '/admin/message-blasts', component: lazy(() => import('../pages/Notifications/Create'))},
  { path: '/orders/view/:id', component: lazy(() => import('../pages/Orders/View')) },
  { path: '/orders/edit/:id', component: lazy(() => import('../pages/Orders/Edit')) },
  { path: '/notifications/create', component: lazy(() => import('../pages/Notifications/Create')) },
  { path: '/notifications/edit/:id', component: lazy(() => import('../pages/Notifications/Edit')) },
  { path: '/admin/create', component: lazy(() => import('../pages/Admin/Create')), },
  { path: '/admin/pop-ups/create', component: lazy(() => import('../pages/PopUps/Create')),},
  { path: '/admin/pop-ups/edit/:id', component: lazy(() => import('../pages/PopUps/Edit')),},
  { path: '/admin/homepage-image/', component: lazy(() => import('../pages/HomepageImage/')),},
  { path: '/admin/file-transfer/send', component: lazy(() => import('../pages/FileTransfer/SendSharedFile')) },
  { path: '/admin/file-transfer/sent/:blobFileName', component: lazy(() => import('../pages/FileTransfer/ViewSentSharedFile')) },
  { path: '/admin/file-transfer/received/:blobFileName', component: lazy(() => import('../pages/FileTransfer/ViewReceivedSharedFile')) },
  //{ path: '/admin/pop-ups/edit/:id', component: lazy(() => import('../pages/HomepageImage/')),},
]

//prettier-ignore
export const userAddressesRoutes = [
  { path: '/admin/edit/:id', component: lazy(() => import('../pages/Admin/Edit')), },
  { path: '/admin/edit/:id/addresses', component: lazy(() => import('../pages/Admin/EditAddresses')), },
]
