import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useKeycloak } from '@react-keycloak/web'
import { Spinner } from '@monorepo/components'
import { UserProfileData, UserProfile } from '@monorepo/interfaces'
import { TableBody } from '@material-ui/core'
import { IconButton } from '@mui/material'
import EngineeringIcon from '@mui/icons-material/Engineering'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { Icon } from '@monorepo/components'
import {
  ChannelHelper,
  DealerHelper,
  UserHelper,
  KeycloakHelper,
  EnvironmentHelper,
} from '@monorepo/infra'
import axios from 'axios'
import * as TreeHelper from '../../helpers/user'

import * as S from './styles'
import DirectoryTreeViewDialog from '../DirectoryTreeViewDialog'
export interface SectionUsersListProps extends UserProfileData {
  loading: boolean
  handleEditUser: (index: number) => void
  handleDeleteUser: (email: string) => void
}

const SectionUsersList: React.FC<SectionUsersListProps> = ({
  users,
  loading = true,
  handleEditUser,
  handleDeleteUser,
}) => {
  const { keycloak } = useKeycloak()
  const currentUser = KeycloakHelper.getTokenParsed(keycloak)
  const { t } = useTranslation('admin')

  const dealerTypes = DealerHelper.getDealerOptions()

  const [directoryTreeViewDialogOpen, setDirectoryTreeViewDialogOpen] =
    useState(false)
  const [directoryTreeViewDialogData, setDirectoryTreeViewDialogData] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any | null | undefined>(undefined)
  const [userActivity, setuserActivity] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<any | null | undefined>([undefined])

  const handleDirectoryTreeViewDialogClose = () => {
    setDirectoryTreeViewDialogOpen(false)
  }

  const getDealerTypeDescription = (dealerType: string) => {
    return dealerTypes.find((item) => item.id === dealerType)?.value
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getMasterDealerIds = (user: any) => {
    const masterDealerIds = ChannelHelper.getMasterDealerIds(
      user?.dealer?.associatedMasterDealers
    )
    return masterDealerIds.length ? masterDealerIds.join(', ') : undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getChannels = (user: any) => {
    const channels = ChannelHelper.getChannels(
      user?.dealer?.associatedMasterDealers
    )
    return channels.length ? channels.join(', ') : undefined
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getHasCoops = (user: any) => {
    return user?.dealer?.hasCoop === true ? 'Yes' : 'No'
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleUserClick = async (user: any) => {
    const masterDealerIds = ChannelHelper.getMasterDealerIds(
      user?.dealer?.associatedMasterDealers
    )

    try {
      const responses = await Promise.all([
        axios.get('/masterdealer/tree', {
          baseURL: process.env.REACT_APP_REST_USER_BASE_URL,
          params: { masterDealerId: masterDealerIds },
        }),
        axios.get('/United/DealerUniqueEmails', {
          baseURL: process.env.REACT_APP_TX_BASE_URL,
        }),
        axios.get(`/log/${user._id}`, {
          baseURL: process.env.REACT_APP_REST_BASE_URL,
          headers: {
            Authorization: `Bearer ${KeycloakHelper.getToken(keycloak)}`,
            'content-type': 'application/json',
          },
        }),
      ])

      if (Array.isArray(responses) && responses.length > 1) {
        setDirectoryTreeViewDialogData(
          TreeHelper.getTree(responses[0].data, responses[1].data?.DealerEmails)
        )
        setuserActivity(TreeHelper.getUserActivityTree(responses[2].data))
        setDirectoryTreeViewDialogOpen(true)
      }
    } catch (error) {
      console.log(error)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleImpersonateUser = async (user?: any) => {
    if (!user?.enabled || !user?.email) {
      return
    }

    const userEmailEncoded = encodeURIComponent(user.email)
    window.open(
      `${process.env.REACT_APP_STOREFRONT_BASE_URL}/impersonate/${userEmailEncoded}?cacheBust=${new Date().getTime()}`,
      '_blank'
    )
  }

  return (
    <S.Container>
      <S.TableContainer>
        <S.Table aria-label="users Table">
          <S.TableHead>
            <S.TableRow>
              <S.TableHeadCell>
                {t('section_users_list.table_title_1')}
              </S.TableHeadCell>
              {/* <S.TableHeadCell>
                {t('section_users_list.table_title_2')}
              </S.TableHeadCell> */}
              <S.TableHeadCell>
                {t('section_users_list.table_title_3')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_users_list.table_title_4')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_users_list.table_title_5')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_users_list.table_title_6')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_users_list.table_title_7')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_users_list.table_title_8')}
              </S.TableHeadCell>
              <S.TableHeadCell>
                {t('section_users_list.table_title_9')}
              </S.TableHeadCell>
              <S.TableHeadCell></S.TableHeadCell>
              {UserHelper.isImpersonatedUserAdmin(keycloak) ||
              UserHelper.isAdmin(currentUser?.groups) ? (
                <S.TableHeadCell></S.TableHeadCell>
              ) : undefined}
              {EnvironmentHelper.isImpersonationEnabled() &&
              (UserHelper.isImpersonatedUserAdmin(keycloak) ||
                UserHelper.isAdmin(currentUser?.groups)) ? (
                <S.TableHeadCell></S.TableHeadCell>
              ) : undefined}
            </S.TableRow>
          </S.TableHead>
          {
            <TableBody>
              {loading ? (
                <S.TbodyRowLoading>
                  <S.TBodyCellLoading scope="row">
                    <Spinner spinnerSize={8} />
                  </S.TBodyCellLoading>
                </S.TbodyRowLoading>
              ) : (
                users?.map((user: UserProfile, index: number) => (
                  <S.TbodyRow
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation()
                      handleUserClick(user)
                    }}
                  >
                    <S.TBodyCell scope="row">
                      {user.firstName + ' ' + user.lastName}
                    </S.TBodyCell>
                    {/* <S.TBodyCell scope="row">{user.company}</S.TBodyCell> */}
                    <S.TBodyCell scope="row">{user.email}</S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {UserHelper.isAdmin(user.groups) ? 'Admin' : 'Member'}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {user.dealerTypes && Array.isArray(user.dealerTypes)
                        ? user.dealerTypes
                            ?.map((dealerType) =>
                              getDealerTypeDescription(dealerType)
                            )
                            .join(', ')
                        : undefined}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">
                      {getMasterDealerIds(user)}
                    </S.TBodyCell>
                    <S.TBodyCell scope="row">{getChannels(user)}</S.TBodyCell>
                    <S.TBodyCell scope="row">{getHasCoops(user)}</S.TBodyCell>
                    <S.TBodyCell scope="row">
                      <S.StatusTag data-status={user?.enabled}>
                        {user?.enabled ? 'Active' : 'Inactive'}
                      </S.StatusTag>
                    </S.TBodyCell>
                    <S.TBodyCell
                      scope="row"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleEditUser(index)
                      }}
                    >
                      <Icon icon="Pencil2" size={24} />
                    </S.TBodyCell>
                    {UserHelper.isImpersonatedUserAdmin(keycloak) ||
                    UserHelper.isAdmin(currentUser?.groups) ? (
                      <S.TBodyCell scope="row">
                        <IconButton
                          aria-label="deleteuser"
                          disabled={!user?.email}
                          title="Delete"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleDeleteUser(user?.email as string)
                          }}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      </S.TBodyCell>
                    ) : undefined}
                    {EnvironmentHelper.isImpersonationEnabled() &&
                    (UserHelper.isImpersonatedUserAdmin(keycloak) ||
                      UserHelper.isAdmin(currentUser?.groups)) ? (
                      <S.TBodyCell scope="row">
                        <IconButton
                          edge="end"
                          aria-label="impersonate"
                          disabled={!user?.enabled || !user?.email}
                          title="Impersonate"
                          onClick={(e) => {
                            e.stopPropagation()
                            handleImpersonateUser(user)
                          }}
                        >
                          <EngineeringIcon />
                        </IconButton>
                      </S.TBodyCell>
                    ) : undefined}
                  </S.TbodyRow>
                ))
              )}
            </TableBody>
          }
        </S.Table>
      </S.TableContainer>
      <DirectoryTreeViewDialog
        open={directoryTreeViewDialogOpen}
        title="Directory"
        data={directoryTreeViewDialogData}
        userActivity={userActivity}
        onClose={handleDirectoryTreeViewDialogClose}
      />
    </S.Container>
  )
}

export default SectionUsersList
